import { get, post, put, del } from './helpers';

// Base path for domains
const BASE_PATH = '/domain';

// GET /domain
const getDomain = async () => {
    return await get(BASE_PATH);
};

// POST /domain
const addDomain = async (data) => {
    await post(BASE_PATH, {
        name: data.name,
        ip: data.ip,
    });
};

// PUT /domain/:id
const editDomain = async (data) => {
    await put(`${BASE_PATH}/${data.id}`, {
        name: data.name,
        ip: data.ip,
    });
};

// DELETE /domain/:id
const deleteDomain = async (id) => {
    await del(`${BASE_PATH}/${id}`);
};

export { getDomain, addDomain, editDomain, deleteDomain };
