import axios from 'axios';

// Base URL for API
const BASE_URL = process.env.VUE_APP_API_BASE_URL;

// Generic GET request
const get = async (url) => {
    let data = null;

    await axios
        .get(BASE_URL + url)
        .then((response) => {
            // Check for valid response status code
            if (response.status !== 200) {
                throw new Error(
                    `Invalid response from server, status code ${response.status}`
                );
            }

            data = response.data;
        })
        .catch((error) => {
            throw new Error(error);
        });

    return data;
};

// Generic POST request
const post = async (url, data) => {
    await axios
        .post(BASE_URL + url, data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
        .then((response) => {
            // Check for valid response status code
            if (response.status !== 200) {
                throw new Error(
                    `Invalid response from server, status code ${response.status}`
                );
            }
        })
        .catch((error) => {
            throw new Error(error);
        });
};

// Generic PUT request
const put = async (url, data) => {
    await axios
        .put(BASE_URL + url, data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
        .then((response) => {
            // Check for valid response status code
            if (response.status !== 200) {
                throw new Error(
                    `Invalid response from server, status code ${response.status}`
                );
            }
        })
        .catch((error) => {
            console.log(error.response.data);
            throw new Error(error);
        });
};

// Generic DELETE request
const del = async (url) => {
    await axios
        .delete(BASE_URL + url)
        .then((response) => {
            // Check for valid response status code
            if (response.status !== 200) {
                throw new Error(
                    `Invalid response from server, status code ${response.status}`
                );
            }
        })
        .catch((error) => {
            throw new Error(error);
        });
};

export { get, post, put, del };
