import { get, post, put, del } from './helpers';

// Base path for servers
const BASE_PATH = '/server';

// GET /server
const getServer = async () => {
    return await get(BASE_PATH);
};

// POST /server
const addServer = async (data) => {
    await post(BASE_PATH, {
        host: data.host,
        ip: data.ip,
        type: data.type,
    });
};

// PUT /server/:id
const editServer = async (data) => {
    await put(`${BASE_PATH}/${data.id}`, {
        name: data.name,
        ip: data.ip,
        type: data.type,
    });
};

// DELETE /server/:id
const deleteServer = async (id) => {
    await del(`${BASE_PATH}/${id}`);
};

export { getServer, addServer, editServer, deleteServer };
